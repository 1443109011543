
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































$bp: 'm';

.flexible-price-details-inner {
  @extend %border-radius-small;

  padding: $spacing;
  background: $c-white;
  border: 1px solid $c-light-grey;

  @include mq($bp) {
    display: flex;
    padding: $spacing * 2;
  }
}

.flexible-price-details__picture {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  aspect-ratio: 4/3;
  margin-bottom: $spacing * 2;

  @include mq($bp) {
    flex: 1;
    aspect-ratio: 16/9;
    margin: 0 $spacing * 2 0 0;
  }
}

.flexible-price-details__content {
  @include mq($bp) {
    flex: 1;
  }
}

.flexible-price-details__content__title {
  margin-bottom: $spacing * 0.75;

  @include mq($bp) {
    margin-bottom: $spacing;
  }
}

.flexible-price-details__content__prices.prices {
  padding: 0;
  border: 0;

  ::v-deep .prices__content__text {
    padding: $spacing 0;
    border-top: 1px solid $c-light-grey;
    border-bottom: 1px solid $c-light-grey;

    @include mq($bp) {
      flex: 1;
    }
  }
}
